import React, { useEffect, useRef, useState } from "react";
import { Card, Input, Checkbox, Button, Typography, Select, Tooltip, AutoComplete, Progress, ProgressProps } from "antd";
import { ConstraintInputType, ConstraintType, InputMaybe } from "../../../../../__generated__/globalTypes";
import { validateConstraint } from "../../../components/workspaces/adaptive-learning/design-validation";
import { useIngredients } from "../../../_shared/hooks";
import { useScenarioDetail } from "../../../_shared/context/scenario-detail-context";
import { ConstraintReason } from "@prisma/client";
import { SaveOutlined } from "@ant-design/icons";
import * as nearley from "nearley";
import { IngredientFromGroup, IngredientGroupData, IngredientGroupDataIn } from "../../../components/project/ingredients-group/ingredients-group.interfaces";
import { useSession } from "../../../_shared/context";
import { useCreateIngredientgroup, useGetIngredientsGroup } from "../../../network/services/ingredient-group.service";
const grammar = require("../../../_shared/utils/constraint-parser/constraint-parser.js")

const { TextArea } = Input;
const { Text } = Typography;

const conicColors: ProgressProps['strokeColor'] = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#FF0000',
};

const capitalizeEachWord = (str: string) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase());

export const ConstraintEditor = () => {

  const { ingredients, ingredientByName } = useIngredients();
  const { user, currentProject, addIngredientGroup, ingredientCompositionList } = useSession();
  const {
    removeConstraint,
    constraints,
    saveConstraints,
  } = useScenarioDetail();
  const createGroupMutation = useCreateIngredientgroup();
  const {
    data: ingredientsGroup,
    isSuccess: ingredientGroupIsSuccess,
    refetch
  } = useGetIngredientsGroup({
    projectId: currentProject?.id,
    organizationId: user?.organizationId,
  });


  const [query, setQuery] = useState("");
  const [comment, setComment] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [reason, setReason] = useState<ConstraintReason | undefined>();
  const [validationMessage, setValidationMessage] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [autocompleteVisible, setAutocompleteVisible] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [selectedIngredientGroupId, setSelectedIngredientGroupId] = useState<string | undefined>(undefined);
  const [selectedIngredientCompositionId, setSelectedIngredientCompositionId] = useState<string | undefined>(undefined);
  const [groupOrCompositionOriginalName, setGroupOrCompositionOriginalName] = useState<string | undefined>(undefined);
  const [groupOrCompositionChangedName, setGroupOrCompositionChangedName] = useState<string | undefined>(undefined);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setIsValid(query !== undefined && reason !== undefined)
  }, [query, reason])

  useEffect(() => {
    if (!ingredientByName || ingredientByName.size === 0) {
      return;
    }
    const ingredientNames = Array.from(ingredientByName.keys())
    let labels = ingredientNames.map(
      (ingredient) => ({
        value: `@${capitalizeEachWord(ingredient).replace(/\s/g, '')}`
      })
    );

    Object.keys(ingredientsGroup).forEach((ig) => {
      labels.push({ value: `@${ingredientsGroup[ig][0].group.name}` })
    })

    ingredientCompositionList.map((i) => {
      labels.push({ value: `@${i.name}` })
    })

    setOptions(labels);
  }, [ingredientByName, ingredientsGroup]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    setQuery(value);

    const lastAtIndex = value.lastIndexOf("@");

    if (lastAtIndex !== -1) {
      const substringAfterAt = value.substring(lastAtIndex + 1).trim();

      // Filtering items to show in @ dropdown
      // Ingredients
      const filteredOptions = Array.from(ingredientByName.keys())
        .filter((ingredient) =>
          ingredient.toLowerCase().includes(substringAfterAt.toLowerCase())
        )
        .map((ingredient) => ({ value: `@${capitalizeEachWord(ingredient).replace(/\s/g, '')}` }));
      // Ingredients Groups
      Object.values(ingredientsGroup)
        .map((groupArray: any[]) => groupArray[0])
        .filter((element: any) =>
          element.group.name.toLowerCase().includes(substringAfterAt.toLowerCase())
        )
        .forEach((element: any) => {
          filteredOptions.push({
            value: `@${element.group.name}`,
          });
        });
      // Ingredients Composition
      ingredientCompositionList
        .filter((ic) =>
          ic.name.toLowerCase().includes(substringAfterAt.toLowerCase())
        )
        .map((ic) => filteredOptions.push({ value: `@${ic.name}` }))


      // Check if input match a group or a composition
      // group
      const matchedGroupId = Object.keys(ingredientsGroup).find(
        (ig) => ingredientsGroup[ig][0].group.name.toLowerCase() === substringAfterAt.toLowerCase()
      );
      if (matchedGroupId) {
        setSelectedIngredientGroupId(matchedGroupId);
        setGroupOrCompositionOriginalName(ingredientsGroup[matchedGroupId][0].group.name);
        const groupNameToShow = `@${ingredientsGroup[matchedGroupId][0].group.name.replace(/\s/g, '')}`;
        setGroupOrCompositionChangedName(groupNameToShow.slice(1));
        value = value.substring(0, lastAtIndex) + groupNameToShow + value.substring(lastAtIndex + substringAfterAt.length + 1);
        setQuery(value);
      }

      // composition
      const matchedComposition = ingredientCompositionList.find((ic) => ic.name.toLowerCase() === substringAfterAt.toLowerCase());
      if (matchedComposition) {
        setSelectedIngredientCompositionId(matchedComposition.id);
        const compositionNameToShow = `@${matchedComposition.name.replace(/\s/g, '')}`;
        value = value.substring(0, lastAtIndex) + compositionNameToShow + value.substring(lastAtIndex + substringAfterAt.length + 1);
        setQuery(value);
      }

      setOptions(filteredOptions);
      setAutocompleteVisible(filteredOptions.length > 0);
      setCursorPosition(lastAtIndex);
    } else {
      setAutocompleteVisible(false);
    }
  };

  const handleCommentChange = (e: { target: { value: any; }; }) => {
    let value = e.target.value
    setComment(value);
  }

  const handleSelect = (selectedIngredient: string) => {
    if (textareaRef.current) {
      const beforeAt = query.substring(0, cursorPosition);

      const afterAtMatch = query.substring(cursorPosition).match(/(\s|[\+\-\*\/<>=!])/);
      const afterAtIndex = afterAtMatch ? cursorPosition + afterAtMatch.index! : query.length;
      const afterAt = query.substring(afterAtIndex);

      const matchedGroup = Object.keys(ingredientsGroup).find(
        (ig) => ingredientsGroup[ig][0].group.name === selectedIngredient.slice(1)
      );
      if (matchedGroup) {
        setSelectedIngredientGroupId(matchedGroup);
        setGroupOrCompositionOriginalName(selectedIngredient.slice(1));
        selectedIngredient = selectedIngredient.replace(/\s/g, '');
        setGroupOrCompositionChangedName(selectedIngredient.slice(1));
      }

      const matchedComposition = ingredientCompositionList.find((ic) => ic.name.toLowerCase() === selectedIngredient.slice(1).toLowerCase());
      if (matchedComposition) {
        setSelectedIngredientCompositionId(matchedComposition.id);
        setGroupOrCompositionOriginalName(matchedComposition.name);
        selectedIngredient = `@${matchedComposition.name.replace(/\s/g, '')}`;
        setGroupOrCompositionChangedName(selectedIngredient.slice(1));
      }

      const newQuery = `${beforeAt}${selectedIngredient} ${afterAt}`.trim();

      setQuery(newQuery);
      textareaRef.current.defaultValue = newQuery;
      setAutocompleteVisible(false);
    }
  };

  const handleSaveConstraint = async () => {
    const parsedConstraint = parseQuery(query) as ConstraintInputType;
    if (parsedConstraint) {
      let validationResponse = validateConstraint(parsedConstraint, ingredients);
      if (validationResponse.isValid) {
        let constraintsToSave;
        if (parsedConstraint.id) {
          // We don't want to acidently pass the old version of the constraint with the same ID
          let otherConstraints = constraints.filter(
            constraint => constraint.id !== parsedConstraint.id
          );
          constraintsToSave = [...otherConstraints, parsedConstraint];
        } else {
          constraintsToSave = [...constraints, parsedConstraint];
        }

        const constraint = await saveConstraints(constraintsToSave);
        setValidationMessage(undefined);
      } else {
        setValidationMessage(validationResponse.description);
      }
    }
    if (parsedConstraint) {
      setQuery("");
      setComment("");
      setSelectedIngredientGroupId(undefined);
      setSelectedIngredientCompositionId(undefined);
      setGroupOrCompositionOriginalName(undefined);
      setGroupOrCompositionChangedName(undefined);
      setReason(undefined);
    }
  };

  function parseQuery(query: string) {
    let constraint: ConstraintInputType = {
      id: '',
      constraintType: ConstraintType.EQUALITY,
      lowerBounds: null,
      upperBounds: null,
      ingredientCompositionId: null,
      coefficients: [],
      values: [],
      variables: [],
    };
    const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
    try {
      parser.feed(query);
      const res = parser.results[0][0][0];
      let queryToSave;
      if (groupOrCompositionOriginalName && groupOrCompositionChangedName)
        queryToSave = query.replace(groupOrCompositionChangedName, groupOrCompositionOriginalName)
      if (res.type === 'simple') {
        if (selectedIngredientGroupId || selectedIngredientCompositionId) {
          if (res.operator[0] === '=') {
            constraint.constraintType = ConstraintType.EQUALITY;
            constraint.values = [{ name: groupOrCompositionOriginalName, value: parseFloat(res.value) }];
          } else if (res.operator[0] === '<' || res.operator[0] === '<=') {
            constraint.constraintType = ConstraintType.RANGE;
            constraint.coefficients = [{ name: groupOrCompositionOriginalName, operator: "range", value: 1 }];
            constraint.lowerBounds = 0
            constraint.upperBounds = res.value ? parseFloat(res.value) : undefined;
          } else if (res.operator[0] === '>' || res.operator[0] === '>=') {
            constraint.constraintType = ConstraintType.RANGE;
            constraint.coefficients = [{ name: groupOrCompositionOriginalName, operator: "range", value: 1 }];
            constraint.lowerBounds = res.value ? parseFloat(res.value) : undefined;
            constraint.upperBounds = 100
          }
          constraint.name = queryToSave ? queryToSave : query;
          selectedIngredientGroupId ? constraint.ingredientGroupId = selectedIngredientGroupId : constraint.ingredientCompositionId = selectedIngredientCompositionId;
        } else {
          if (res.operator[0] === '=') {
            constraint.constraintType = ConstraintType.EQUALITY;
            constraint.values = [{ name: res.ingredient.trim(), value: parseFloat(res.value) }];
          } else if (res.operator[0] === '<' || res.operator[0] === '<=') {
            constraint.constraintType = ConstraintType.RANGE;
            constraint.coefficients = [{ name: res.ingredient.trim(), value: 1 }];
            constraint.lowerBounds = findLowestValue(res.ingredient.trim());
            constraint.upperBounds = res.value ? parseFloat(res.value) : undefined;
          } else if (res.operator[0] === '>' || res.operator[0] === '>=') {
            constraint.constraintType = ConstraintType.RANGE;
            constraint.coefficients = [{ name: res.ingredient.trim(), value: 1 }];
            constraint.lowerBounds = res.value ? parseFloat(res.value) : undefined;
            constraint.upperBounds = findHighestValue(res.ingredient.trim());
          }
          constraint.name = queryToSave ? queryToSave : query;
        }
      } else if (res.type === 'range') {
        if (selectedIngredientGroupId || selectedIngredientCompositionId) {
          if (((res.lower.operator[0] === '<' || res.lower.operator[0] === '<=') && (res.upper.operator[0] === '<' || res.upper.operator[0] === '<=')) ||
            ((res.lower.operator[0] === '>' || res.lower.operator[0] === '>=') && (res.upper.operator[0] === '>' || res.upper.operator[0] === '>='))) {
            constraint.coefficients = [{ name: groupOrCompositionOriginalName, value: 1, operator: "range" }];
            constraint.lowerBounds = res.upper.value;
            constraint.upperBounds = res.lower.value;
            constraint.constraintType = ConstraintType.RANGE;
            selectedIngredientGroupId ? constraint.ingredientGroupId = selectedIngredientGroupId : constraint.ingredientCompositionId = selectedIngredientCompositionId;
          }
        } else if (((res.lower.operator[0] === '<' || res.lower.operator[0] === '<=') && (res.upper.operator[0] === '<' || res.upper.operator[0] === '<=')) || ((res.lower.operator[0] === '>' || res.lower.operator[0] === '>=') && (res.upper.operator[0] === '>' || res.upper.operator[0] === '>='))) {
          constraint.constraintType = ConstraintType.RANGE;
          constraint.coefficients = [{ name: res.ingredient.trim(), value: 1 }];
          constraint.lowerBounds = res.lower.value;
          constraint.upperBounds = res.upper.value;
        }
        constraint.name = queryToSave ? queryToSave : query;
      } else if (res.type === 'assignment') {
        let ingredientGroupData: IngredientGroupData = { name: res.group, lowerBound: 0, upperBound: 100, ingredient: [], groupSum: false };
        res.ingredients.map((i: string) => {
          const ingredient = ingredients.find((ing) => ing.ingredient.labelName === i)
          const ingredientToGroup: IngredientFromGroup = { percentage: 0, ingredientName: ingredient?.ingredient.name!, ingredientId: ingredient?.ingredient.id! }
          ingredientGroupData.ingredient.push(ingredientToGroup);
        })
        addGroup(ingredientGroupData);
        return undefined;
      }

      constraint.reason = reason ?? undefined;
      constraint.comment = comment ?? undefined;
      return constraint;
    } catch (error) {
      console.error("Error parsing query:", error);
      return null;
    }
  }

  const findLowestValue = (ingredient: string): number => {
    const found = ingredients.find((i) => i.ingredient.name === ingredient)
    if (found && found.lowerLimit)
      return found.lowerLimit
    else
      return 0
  };

  const findHighestValue = (ingredient: string): number => {
    const found = ingredients.find((i) => i.ingredient.name === ingredient)
    if (found && found.upperLimit)
      return found.upperLimit
    else
      return 100
  };

  const addGroup = (e: IngredientGroupData) => {
    const payload: IngredientGroupDataIn = {
      group_name: e.name,
      creator_id: user?.id,
      ingredientsList: e.ingredient.map(i => ({
        ingredient_id: i.ingredientId,
        percentage: 1,
      })),
      lower_bound: e.lowerBound,
      upper_bound: e.upperBound,
      groupSum: e.groupSum
    };
    createGroupMutation.mutate(
      {
        projectId: currentProject?.id ?? '',
        organizationId: user?.organizationId ?? '',
        ingredientGroup: payload,
      },
      {
        onSuccess: async (response: any) => {
          console.log(response);
          const newgroup = response.data;
          addIngredientGroup({
            name: newgroup.name,
            id: newgroup.id,
            lowerBound: newgroup.lowerBound,
            upperBound: newgroup.upperBound,
            ingredient: e.ingredient,
            groupSum: e.groupSum
          });
          refetch()
        },
      }
    );
  };

  // // TESTS
  // const queries = [
  //   "@ingredient > 4",
  //   "4 < @ingredient < 9",
  //   "@ingredientGroupA = @ingredientA, @ingredientB, @ingredientC",
  //   "IF(@ingredientA < 4, @ingredientD > 9)",
  //   "IF(@IngredientA > 0, @IngredientB = 5, @IngredientC = 10)"
  // ];

  // queries.forEach(query => {
  //   console.log(`Query: ${query}`);
  //   console.log("Parsed:", parseQuery(query));
  //   console.log("----------------------------");
  // });

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}>
      <p>Type @ to reference ingredients</p>
      <div style={{ width: "100%" }}>
        <AutoComplete
          options={options}
          open={autocompleteVisible}
          value={query}
          onSelect={handleSelect}
          style={{
            left: 0,
            top: "100%",
            width: "100%",
            zIndex: 10,
          }}
        >
          <TextArea
            ref={textareaRef}
            value={query}
            onChange={handleQueryChange}
            placeholder="Type @ to insert an ingredient. Example: @Sugar <= 10"
          />
        </AutoComplete>
      </div>

      <div
        style={{ display: 'flex', gap: 6, alignItems: 'center', marginBottom: '10px', marginTop: 8, justifyContent: 'space-between' }}
      >
        <span style={{ alignItems: 'center' }}>Reason:</span>
        <Select
          style={{ width: '37vh' }}
          value={reason ?? undefined}
          options={[
            { value: ConstraintReason.SAFETY, label: 'Safety' },
            { value: ConstraintReason.REGULATORY, label: 'Regulatory' },
            { value: ConstraintReason.MANUFACTURING, label: 'Manufacturing' },
            { value: ConstraintReason.NUTRITIONAL, label: 'Nutritional' },
          ]}
          onChange={(value) => setReason(value as ConstraintReason)}
        />
      </div>

      <div
        style={{ display: 'flex', gap: 6, alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between' }}
      >
        <span style={{ alignItems: 'center' }}>Comment:</span>
        <Input
          value={comment}
          style={{ width: '37vh' }}
          onChange={handleCommentChange}
          placeholder="Insert comments about the constraint"
          maxLength={250}
        />
      </div>

      <Tooltip title={!isValid ? 'Invalid query format. Use @Ingredient followed by a constraint and remember to select a Reason' : undefined}>
        <Button type="primary" icon={<SaveOutlined />} block disabled={!isValid} onClick={() => handleSaveConstraint()}
          style={{
            backgroundColor: isValid ? "#ef4136" : undefined,
            borderColor: isValid ? "#ef4136" : undefined,
          }}
        >
          Save Constraint
        </Button>
      </Tooltip>
      {validationMessage && <Text type="danger">{validationMessage}</Text>}

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginTop: 25,
          border: 'rgb(252 130 130) solid 1px',
          borderRadius: 10,
          padding: 10
        }}
      >
        <Text strong style={{ color: 'black' }}>Current constraints reduce your potential solutions by</Text>
        <Progress
          type="dashboard"
          percent={93}
          strokeColor={conicColors}
          size="small"
        />
      </div>

    </div>
  );
};