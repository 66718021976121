import { useQuery } from "react-query";
import { IngredientCompositionRepository } from "../repositories/ingredient-composition.repository";

export const useGetIngredientsComposition = (data: {
  organizationId?: string;
  projectId?: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getIngredientComposition`, data.organizationId, data.projectId],
    IngredientCompositionRepository.getIngredientsCompositions,
    {
      enabled: !!data.projectId && !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};